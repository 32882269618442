import React from 'react'
import Pagination from "react-js-pagination";

import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'
import Titles from "./editable/Titles";
import Collapsible from "react-collapsible";
import DataParser from "../../utils/DataParser";
import FileUploader from "../common/FileUploader";

export default class Block58 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      pageCount: 1,
      uuid: "cb_"+General.uuid(),
      category: General.getUrlParameter("category") || "",
      searchTerm: "",
      categories: [],
      filteredData: [],
      pageUrl: window.location.href
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {

      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentDidMount() {
    this._parseFile()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._parseFile())
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _parseFile(){
    let {
      data,
      pageUrl,
      category
    } = this.state

    let paginatedData = []

    if(data.extra?.file){
      let fileUrl = data.extra.file.url
      fetch(fileUrl).then(response => {
        return response.arrayBuffer()
      }).then((ab) => {
        let fileData = new Uint8Array(ab)
        let [headers, mediaData] = DataParser.formatData(fileData, fileUrl, pageUrl)
        let filteredData = []
        if(data.extra.page_size === "all"){
          paginatedData = mediaData
        }else{
          if(category){
            filteredData = category ? mediaData.filter(item => item.category === category) : mediaData;
            paginatedData = DataParser.paginateData(filteredData, data.extra.page_size, 1, category)
          }else {
            paginatedData = DataParser.paginateData(mediaData, data.extra.page_size, 1, category)
          }
        }
        this._getCategories(mediaData)
        this.setState({headers, mediaData, filteredData, paginatedData, pageCount: 1})
      })
    }
  }

  _getCategories(mediaData){
    let categories = DataParser.getCategories(mediaData)
    this.setState({categories})
  }

  _handleSearch = General.debounce(() => {
    let {
      data,
      category,
      mediaData,
      searchTerm,
      filteredData,
      paginatedData
    } = this.state

    let newData;

    if(category){
      filteredData = DataParser.searchData(filteredData, searchTerm, data.extra?.page_size, 1)
    }else{
      filteredData = DataParser.searchData(mediaData, searchTerm, data.extra?.page_size, 1)
    }

    this.setState({paginatedData: filteredData, filteredData, pageCount: 1})
  }, 500)

  _renderModalContent(data) {

    let className = "field-file "
    className += data.is_required ? "validate-required" : ""
    if(data.error){
      className += " validation-error"
    }

    return (
      <>
        <Border>
          <Collapsible trigger="Data File">
            <br></br>
            <div className={className}>
              <div className="row">
                <div className="col-md-12">
                  Please <a
                  href="javascript:void(0)"
                    className="cursor-pointer"
                    onClick={() => {
                      window.open('/assets/template/Template File.xlsx', '_blank')
                    }}
                  >
                    download the template file
                  </a>, fill in all available columns and upload below.
                </div>
              </div>
              <br/>
              <FileUploader
                key={data.extra?.file?.id}
                placeholder={"Upload Data File (.xlsx)"}
                endpoint={window.Api.Files}
                params={{website_id: window.CURRENT_WEBSITE_ID}}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onUploading={() => {
                  this.setState({loading: true})
                }}
                onUploaded={file => {
                  data.error = false
                  data.extra.file = file
                  this.setState({data}, () => {
                    this._parseFile()
                    this._update(data)
                  })
                }}
                onRemoved={() => {
                  data.extra.file = null
                  this.setState({data}, () => {
                    this._parseFile()
                    this._update(data)
                  })
                }}
              />
              <br/>
              <div className="row">
                <div className="col-md-12">
                  Export current <a
                  href="javascript:void(0)"
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(`${data.extra?.file.url}`, '_blank')
                  }}
                >
                  file
                </a>.
                </div>
              </div>
            </div>
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Layout">
            <br></br>
            <div># of Items Per Page</div>
            <select
              value={data.extra?.page_size || "4"}
              onChange={(event) => {
                data.extra.page_size = event.target.value;
                this._update(data);
                this._parseFile();
              }}
            >
              <option value="4">4 Items</option>
              <option value="5">5 Items</option>
              <option value="8">8 Items</option>
              <option value="10">10 Items</option>
              <option value="16">16 Items</option>
              <option value="20">20 Items</option>
              <option value="all">All Items</option>
            </select>
            <br/><br/>
            <div># Of Columns On Desktop</div>
            <select
              value={data.extra?.columns || "4"}
              onChange={(event) => {
                data.extra.columns = event.target.value;
                this._update(data);
                this._parseFile();
              }}
            >
              <option value="5">5 Columns</option>
              <option value="4">4 Columns</option>
              <option value="3">3 Columns</option>
              <option value="2">2 Columns</option>
            </select>
            <br/><br/>
            <div># Of Columns On Mobile</div>
            <select
              value={data.extra?.mobile_columns || "3"}
              onChange={(event) => {
                data.extra.mobile_columns = event.target.value;
                this._update(data);
              }}
            >
              <option value="4">4 Columns</option>
              <option value="3">3 Columns</option>
              <option value="2">2 Columns</option>
            </select>
          </Collapsible>
        </Border>
      </>
    );
  }

  _renderBlockStyle(){
    let {
      data
    } = this.state

    return (
      <style dangerouslySetInnerHTML={{__html: `
        #${data.anchor}.block-58 .item a span {
          color: ${data.settings.quaternary_color} !important;
          background-color: ${data.settings.quinary_color} !important;
        }
        .react-js-pagination li a {
          color: ${data.settings.quinary_color};
        }
        .react-js-pagination li a {
          color: ${data.settings.quinary_color};
        }
        .react-js-pagination .page-item.active {
          border-color: ${data.settings.quinary_color};
        }
      `}}/>
    )
  }

  render(){
    let {
      data,
      editable,
      uuid,
      key,
      pageCount,
      mediaData,
      category,
      categories,
      searchTerm,
      filteredData,
      paginatedData
    } = this.state
    let rowColumnsCss = data.extra?.columns
    if(!rowColumnsCss){
      rowColumnsCss = 4
    }
    let rowMobileColumnsCss = data.extra?.mobile_columns
    if(!rowMobileColumnsCss){
      rowMobileColumnsCss = 3
    }

    let showPagination = false

    if((category.length > 0 || searchTerm.length > 0) && (filteredData?.length > data.extra.page_size)){
      showPagination = true
    }else if (category.length < 1 && searchTerm < 1 && (mediaData?.length > paginatedData?.length)){
      showPagination = true
    }

    return (
      <>
        <Section
          data={ data }
          index={ this.props.index }
          className={ " block-58 text-center " + this.props.className }
          editable={ editable }
          renderModalContent={() => this._renderModalContent(data)}
          onUpdated={data => this._update(data)}
          primaryColor={false}
          secondaryColor={false}
          quaternaryColorTitle="Overlay Text Colour"
          quinaryColorTitle="Overlay Background Colour"
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
        >
          <div className="container">

            <Titles
              data={ data }
              editable={ editable }
              headingNumber={2}
              onUpdated={data => this._update(data)}
            />

            <div className="row text-center justify-content-center mb-5">
              <div className={"col-lg-4"}>
                <p className="b-sub-heading lead pre m-auto pr-3">Search</p>
                <div className="input-group-text">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={e => {
                      this.setState({
                        searchTerm: e.target.value
                      }, () => this._handleSearch())
                    }}
                  />
                </div>
              </div>
              <div className={"col-lg-4"}>
                <p className="b-sub-heading lead pre m-auto pr-3">Filter</p>
                <div className="input-select input-select--borderless">
                  <select
                    name="category"
                    value={category}
                    onChange={e => {
                      let category = e.target.value
                      let newData;
                      if(category){
                        filteredData = category ? mediaData.filter(item => item.category === category) : mediaData;
                        newData = DataParser.paginateData(filteredData, data.extra.page_size, 1, category)
                      }else {
                        newData = DataParser.paginateData(mediaData, data.extra.page_size, 1, category)
                      }
                      this.setState({
                        paginatedData: newData,
                        filteredData,
                        pageCount: 1,
                        category
                      })
                    }}
                  >
                    <option value="">All Categories</option>
                    {
                      categories.map(category => (
                        <option value={category}>{category}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>

            <div
              className={`row row-cols-${rowMobileColumnsCss} row-cols-md-${rowColumnsCss}`}
            >
              {
                paginatedData && paginatedData.map(item => {
                  return (
                    <>
                      <div className="col item">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            if(item.link) {
                              window.open(item.link, '_self')
                            }
                          }}
                        >
                          <img
                            key={item.image}
                            src={item.image}
                            alt=""
                            loading="lazy"
                            className='shimmer'
                          />
                          <span>
                            <i className='name'>{item.name}</i>
                            <br/>
                            {item.year && <small className="m_t">Year: {item.year}</small>}
                            {item.cast && <small>Cast: {item.cast}</small>}
                            {item.genre && <small>Genre: {item.genre}</small>}
                          </span>
                        </a>
                      </div>
                    </>
                  )
                })
              }
            </div>
            {
              showPagination &&
              <>
                <div className="row mt-5">
                  <Pagination
                    activePage={parseInt(pageCount)}
                    itemsCountPerPage={data.extra?.page_size || 4}
                    totalItemsCount={mediaData.length}
                    pageRangeDisplayed={5}
                    hideFirstLastPages={true}
                    hideDisabled={true}
                    innerClass={"pagination react-js-pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    activeClass={"active"}
                    onChange={(page) => {
                      let newData;
                      if(category){
                        filteredData = category ? mediaData.filter(item => item.category === category) : mediaData;
                        newData = DataParser.paginateData(filteredData, data.extra.page_size, page, category)
                      }else {
                        newData = DataParser.paginateData(mediaData, data.extra.page_size, page, category)
                      }
                      this.setState({
                        paginatedData: newData,
                        pageCount: page
                      })
                    }}
                  />
                </div>
              </>
            }
          </div>
        </Section>
        {this._renderBlockStyle()}
      </>
    )
  }
}
